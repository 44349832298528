.EasyMDEContainer {
    p,
    ol,
    ul,
    li,
    span,
    strong,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    em,
    blockquote {
        all: revert !important;
        white-space: 'pre-wrap';
        font-family: 'Roboto' !important;
    }
}
