html,
body {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    min-height: 100vh;
    // overflow-x: clip;
}

html * {
    font-family: 'Roboto', sans-serif;
    /* font-family: Roboto, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI,
        Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue; */
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

html::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

html::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

html::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}
