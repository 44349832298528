.slick-list {
    margin: 0 -5px;
}
.slick-slide > div {
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-dots {
    // bottom: -30px !important;
}

.slick-dots li button:before {
    opacity: 1 !important;
    width: 11px !important;
    height: 11px !important;
    border-radius: 50px;
    color: transparent !important;
    border: 1px solid #0053cd;
}

.slick-dots li.slick-active button:before {
    border: 1px solid #0053cd;
    color: transparent;
    background: #0053cd;
}
